.custom-control-icon-line {
	background-image: url('../Icons/DrawCutLine.svg');
	width: 28px !important;
    height: 28px !important;
    position: relative;
    right: 4px;
    top: -3.65px;
}

.custom-control-icon-circle {
	background-image: url('../Icons/PivotCircle.svg');
	width: 23px !important;
	height: 23px !important;
	position: relative;
	left: -1px;
	top: -1px;
}

.custom-control-icon-undo-last {
	background-image: url('../Icons/UndoLast.svg');
	width: 23px !important;
	height: 23px !important;
	position: relative;
	left: -1px;
	top: -3px;
}

.custom-control-icon-reset {
	background-image: url('../Icons/Reset.svg');
	width: 23px !important;
	height: 23px !important;
	position: relative;
	left: -1px;
	top: -1px;
}

a.leaflet-buttons-control-button:hover {
	background-color: #C45513;
}